import { edgeToolApi } from './edgeToolApi';

// Note that enhancements here are also visible *at runtime* in the
// default exported edgeToolApi object that was generated.
//
// However, hooks for any changed endpoints will have to be explicitly
// exported from this file.

export const enhancedEdgeToolApi = edgeToolApi.enhanceEndpoints({
    addTagTypes: [
        'Edges',
        'DeviceCertificate',
        'EdgeRequest',
        'DeploymentList',
        'EdgeDeploymentById',
        'EdgeDeploymentDraftById',
        'ModuleDeploymentProperties',
        'ModuleDefinition',
        'WorkloadDefinitionById',
        'WorkloadDefinition',
        'DeploymentSecretList',
        'EdgesCountPerRegion',
        'UserRoleList',
        'AllRoleDefinition',
        'RoleDefinition',
        'RoleDefinitionSchema',
        'UserRoleListByRole',
        'LoggedInUserRoles',
        'NotificationEmailAddressList',
        'NotificationPurpose',
        'SiteEdgeList',
        'WorkloadDefinitionBySearchText',
        'AuditEventList'
    ],
    endpoints: {
        getHubByIotHubIdEdges: {
            // need to be included when using forceRefetch or merge
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName
            },
            // we need to ignore cache in case something of args are changed
            forceRefetch({ currentArg, previousArg }) {
                return currentArg?.iotHubId !== previousArg?.iotHubId
                    || currentArg?.pageNumber !== previousArg?.pageNumber
                    || currentArg?.searchText !== previousArg?.searchText
            },
            // use this for infinite scroll mechanism
            merge: (currentCache, newItems) => {
                if (newItems.pageNumber === 1)
                    return newItems;
                return {
                    ...newItems,
                    edgeDevices: [...currentCache.edgeDevices ?? [], ...newItems.edgeDevices ?? []]
                }
            },
            providesTags: (result, error) =>
                result ?
                    [{ type: 'Edges' as const, id: "edgeList" }]
                    : ['ERROR'],
        },
        getDeviceCertificateInfoByDeviceId: {
            providesTags: (result, error, { deviceId }) =>
                result ?
                    [{ type: 'DeviceCertificate' as const, id: deviceId }]
                    : ['ERROR'],
        },
        postEdgeByIotHubIdAndDeviceIdInstallCertificates: {
            invalidatesTags: (result, error, { deviceId }) =>
                [
                    { type: 'DeviceCertificate' as const, id: deviceId },
                    { type: 'DeviceCertificate' as const, id: 'LIST' }
                ]
        },
        postDeviceCertificateRenew: {
            invalidatesTags: (result, error, { deviceCertificateInformation }) =>
                [{ type: 'DeviceCertificate' as const, id: deviceCertificateInformation.deviceId! }]
        },
        deleteEdgeByIotHubIdAndDeviceId: {
            invalidatesTags: (result, error, { iotHubId, deviceId }) =>
                [
                    { type: 'EdgeRequest' as const, id: 'LIST' },
                    { type: 'Edges' as const, id: 'edgeList' },
                    { type: 'SiteEdgeList' as const },
                ],
        },
        postEdgeByIotHubIdAndDeviceIdMoveEdgeRegistration: {
            invalidatesTags: (result, error, { iotHubId, deviceId, moveRegistrationRequest }) =>
                [
                    { type: 'EdgeRequest' as const, id: 'LIST' },
                    { type: 'Edges' as const, id: 'edgeList' },
                    { type: 'SiteEdgeList' as const },
                ],
        },
        // edge request
        getEdgeRequest: {
            // need to be included when using forceRefetch or merge
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName
            },
            // we need to ignore cache in case something of args are changed
            forceRefetch({ currentArg, previousArg }) {
                return currentArg?.statuses !== previousArg?.statuses
                    || currentArg?.searchText !== previousArg?.searchText
                    || currentArg?.page !== previousArg?.page
                    || currentArg?.engineerEmail !== previousArg?.engineerEmail
                    || currentArg?.requestor !== previousArg?.requestor
            },
            // use this for infinite scroll mechanism
            merge: (currentCache, newItems) => {
                if (newItems.pageNumber === 1)
                    return newItems;
                return {
                    ...newItems,
                    edgeRequests: [...currentCache.edgeRequests ?? [], ...newItems.edgeRequests ?? []]
                }
            },
            providesTags: (result, error) =>
                result && result.edgeRequests ?
                    [
                        ...result.edgeRequests.map(({ deviceId }) => ({ type: 'EdgeRequest' as const, id: deviceId! })),
                        { type: 'EdgeRequest' as const, id: 'LIST' }
                    ]
                    : [{ type: 'EdgeRequest' as const, id: 'LIST' }]
            ,
        },
        postEdgeRequest: {
            invalidatesTags: (result, error, { edgeRequest }) =>
                result && edgeRequest && edgeRequest.deviceId ?
                    [
                        { type: 'EdgeRequest' as const, id: 'LIST' },
                        { type: 'EdgeRequest' as const, id: edgeRequest.deviceId },
                        { type: 'Edges' as const, id: 'edgeList' },
                        { type: 'EdgesCountPerRegion' as const, id: 'EdgesCountPerRegion' },
                        { type: 'SiteEdgeList' as const },
                    ]
                    :
                    [
                        { type: 'EdgeRequest' as const, id: 'LIST' },
                        { type: 'Edges' as const, id: 'edgeList' },
                        { type: 'EdgesCountPerRegion' as const, id: 'EdgesCountPerRegion' },
                        { type: 'SiteEdgeList' as const },
                    ],
        },
        postEdgeRequestByEdgeRequestIdRestartWorkflow: {
            invalidatesTags: (result, error, { edgeRequestId }) =>
                [
                    { type: 'EdgeRequest' as const, id: 'LIST' },
                    { type: 'EdgeRequest' as const, id: edgeRequestId },
                    { type: 'EdgesCountPerRegion' as const, id: 'EdgesCountPerRegion' },
                    { type: 'SiteEdgeList' as const },
                ]
        },
        getEdgeRequestByEdgeRequestId: {
            providesTags: (result, error, { edgeRequestId }) =>
                [{ type: 'EdgeRequest' as const, id: edgeRequestId }]
        },
        deleteEdgeRequestByEdgeRequestIdWithdraw: {
            invalidatesTags: (result, error, { edgeRequestId }) =>
                [
                    { type: 'EdgeRequest' as const, id: 'LIST' },
                    { type: 'EdgeRequest' as const, id: edgeRequestId },
                    { type: 'EdgesCountPerRegion' as const, id: 'EdgesCountPerRegion' },
                    { type: 'Edges' as const, id: 'edgeList' },
                    { type: 'SiteEdgeList' as const },
                ],
        },
        patchEdgeRequestByEdgeRequestIdConfirmMoveRegistration: {
            invalidatesTags: (result, error, { edgeRequestId }) =>
                [
                    { type: 'EdgeRequest' as const, id: 'LIST' },
                    { type: 'EdgeRequest' as const, id: edgeRequestId },
                    { type: 'Edges' as const, id: 'edgeList' },
                ],
        },
        patchEdgeRequestByEdgeRequestIdComplete: {
            invalidatesTags: (result, error, { edgeRequestId }) =>
                [
                    { type: 'EdgeRequest' as const, id: 'LIST' },
                    { type: 'EdgeRequest' as const, id: edgeRequestId }
                ],
        },
        deleteEdgeRequestByEdgeRequestIdMarkInvalid: {
            invalidatesTags: (result, error, { edgeRequestId }) =>
                [
                    { type: 'EdgeRequest' as const, id: 'LIST' },
                    { type: 'EdgeRequest' as const, id: edgeRequestId },
                    { type: 'EdgesCountPerRegion' as const, id: 'EdgesCountPerRegion' },
                    { type: 'Edges' as const, id: 'edgeList' },
                    { type: 'SiteEdgeList' as const },
                ],
        },
        patchEdgeRequestByEdgeRequestId: {
            invalidatesTags: (result, error, { edgeRequestId }) =>
                [
                    { type: 'EdgeRequest' as const, id: 'LIST' },
                    { type: 'EdgeRequest' as const, id: edgeRequestId }
                ],
        },
        patchEdgeRequestByEdgeRequestIdRedeploy: {
            invalidatesTags: (result, error, { edgeRequestId }) =>
                [
                    { type: 'EdgeRequest' as const, id: 'LIST' },
                    { type: 'EdgeRequest' as const, id: edgeRequestId }
                ],
        },
        getConfigurationFeatures: {
            // Enabled features should not change that often
            keepUnusedDataFor: 3600,
        },
        // deployment
        postEdgeDeploymentDraft: {
            invalidatesTags: (result, error, { edgeDeployment }) =>
                [
                    { type: 'DeploymentList' as const, id: "deploymentList" },
                    { type: 'EdgeDeploymentDraftById' as const, id: edgeDeployment.deviceId! }
                ],
        },
        postEdgeDeploymentByEdgeDeploymentIdRefreshStatus: {
            invalidatesTags: (result, error, { edgeDeploymentId }) =>
                [
                    { type: 'DeploymentList' as const, id: "deploymentList" },
                    { type: 'EdgeDeploymentById' as const, id: edgeDeploymentId }
                ],
        },
        getEdgeDeploymentByDeploymentId: {
            providesTags: (result, error, { deploymentId }) =>
                result ?
                    [
                        { type: 'EdgeDeploymentById' as const, id: deploymentId },
                        { type: 'EdgeDeploymentById' as const, id: "deployments" }
                    ]
                    : ['ERROR'],
        },
        getEdgeDeploymentByEdgeDeploymentIdConfiguration: {
            providesTags: (result, error, { edgeDeploymentId }) =>
                result ?
                    [{ type: 'EdgeDeploymentById' as const, id: edgeDeploymentId }]
                    : ['ERROR'],
        },
        getEdgeDeploymentList: {
            // need to be included when using forceRefetch or merge
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName
            },
            // we need to ignore cache in case something of args are changed
            forceRefetch({ currentArg, previousArg }) {
                return currentArg?.statuses !== previousArg?.statuses
                    || currentArg?.deviceIdSearchText !== previousArg?.deviceIdSearchText
                    || currentArg?.page !== previousArg?.page
            },
            // use this for infinite scroll mechanism
            merge: (currentCache, newItems) => {
                if (newItems.pageNumber === 1)
                    return newItems;
                return {
                    ...newItems,
                    edgeDeployments: [...currentCache.edgeDeployments ?? [], ...newItems.edgeDeployments ?? []]
                }
            },
            providesTags: (result, error) =>
                result ?
                    [{ type: 'DeploymentList' as const, id: 'deploymentList' }]
                    : ['ERROR'],
        },
        patchEdgeDeploymentByDeploymentId: {
            invalidatesTags: (result, error, { deploymentId }) =>
                [
                    { type: 'DeploymentList' as const, id: "deploymentList" },
                    { type: 'EdgeDeploymentById' as const, id: deploymentId }
                ],
        },
        deleteEdgeDeploymentByDeploymentId: {
            invalidatesTags: (result, error, { deploymentId }) =>
                [
                    { type: 'DeploymentList' as const, id: "deploymentList" },
                    { type: 'EdgeDeploymentDraftById' as const, id: "deploymentDeviceId" }
                ],
        },
        getEdgeDeploymentDraftByDeviceId: {
            providesTags: (result, error, { deviceId }) =>
                result ?
                    [
                        { type: 'EdgeDeploymentDraftById' as const, id: deviceId },
                        { type: 'EdgeDeploymentDraftById' as const, id: "deploymentDeviceId" }
                    ]
                    : ['ERROR'],
        },
        postEdgeDeploymentByEdgeDeploymentIdConfiguration: {
            invalidatesTags: (result, error, { edgeDeploymentId }) =>
                [
                    { type: 'DeploymentList' as const, id: "deploymentList" },
                    { type: 'EdgeDeploymentById' as const, id: edgeDeploymentId },
                    { type: 'Edges' as const, id: 'edgeList' }
                ],
        },
        postEdgeDeploymentByEdgeDeploymentIdClone: {
            invalidatesTags: (result, error, { edgeDeploymentId }) =>
                [
                    { type: 'DeploymentList' as const, id: "deploymentList" },
                ],
        },
        // modules definitions
        getModuleDefinition: {
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName
            },
            forceRefetch({ currentArg, previousArg }) {
                return currentArg?.page !== previousArg?.page
            },
            merge: (currentCache, newItems) => {
                if (newItems.pageNumber === 1)
                    return newItems;
                return {
                    ...newItems,
                    moduleDefinitions: [...currentCache.moduleDefinitions ?? [], ...newItems.moduleDefinitions ?? []]
                }
            },
            providesTags: (result, error) =>
                result ?
                    [{ type: 'ModuleDefinition' as const, id: 'moduleDefinitionList' }]
                    : ['ERROR'],
        },
        getModuleDefinitionByModuleDefinitionId: {
            providesTags: (result, error) =>
                result ?
                    [{ type: 'ModuleDefinition' as const, id: 'moduleDefinitionListByModuleId' }]
                    : ['ERROR'],
        },
        postModuleDefinition: {
            invalidatesTags: (result, error) =>
                [
                    { type: 'ModuleDefinition' as const, id: 'moduleDefinitionList' },
                    { type: 'ModuleDefinition' as const, id: 'moduleDefinitionListByModuleId' },
                ],
        },
        patchModuleDefinitionByModuleDefinitionIdArchive: {
            invalidatesTags: (result, error) =>
                [
                    { type: 'ModuleDefinition' as const, id: 'moduleDefinitionList' },
                    { type: 'ModuleDefinition' as const, id: 'moduleDefinitionListByModuleId' },
                    { type: 'EdgeDeploymentById' as const },
                ],
        },
        // workloads definitions
        getWorkloadDefinition: {
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName
            },
            forceRefetch({ currentArg, previousArg }) {
                return currentArg?.page !== previousArg?.page
            },
            merge: (currentCache, newItems) => {
                if (newItems.pageNumber === 1)
                    return newItems;
                return {
                    ...newItems,
                    workloads: [...currentCache.workloads ?? [], ...newItems.workloads ?? []]
                }
            },
            providesTags: (result, error) =>
                result ?
                    [{ type: 'WorkloadDefinition' as const, id: 'workloadDefinitionList' }]
                    : ['ERROR'],
        },
        postWorkloadDefinition: {
            invalidatesTags: (result, error) =>
                [
                    { type: 'WorkloadDefinition' as const, id: 'workloadDefinitionList' },
                    { type: 'WorkloadDefinition' as const, id: 'workloadDefinitionSchema' },
                    { type: 'EdgeDeploymentById' as const, id: "deployments" },
                    { type: 'WorkloadDefinitionById' as const }
                ],
        },
        getWorkloadDefinitionByWorkloadDefinitionIdSchema: {
            providesTags: (result, error) =>
                result ?
                    [{ type: 'WorkloadDefinition' as const, id: 'workloadDefinitionSchema' }]
                    : ['ERROR'],
        },
        patchWorkloadDefinitionByWorkloadDefinitionIdArchive: {
            invalidatesTags: (result, error) =>
                [
                    { type: 'WorkloadDefinition' as const, id: 'workloadDefinitionList' },
                    { type: 'EdgeDeploymentById' as const },
                    { type: 'WorkloadDefinitionById' as const }
                ],
        },
        // deployment workload
        getEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadId: {
            providesTags: (result, error, { edgeDeploymentId, workloadId }) =>
                result ?
                    [{ type: 'WorkloadDefinitionById' as const, id: workloadId }]
                    : ['ERROR'],
        },
        deleteEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadId: {
            invalidatesTags: (result, error, { edgeDeploymentId, workloadId }) =>
                [
                    { type: 'EdgeDeploymentById' as const, id: edgeDeploymentId },
                    { type: 'WorkloadDefinitionById' as const, id: workloadId }
                ],
        },
        postEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadId: {
            invalidatesTags: (result, error, { edgeDeploymentId, workloadId }) =>
                [
                    { type: 'EdgeDeploymentById' as const, id: edgeDeploymentId },
                    { type: 'WorkloadDefinitionById' as const, id: workloadId }
                ],
        },
        patchEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadId: {
            invalidatesTags: (result, error, { edgeDeploymentId, workloadId }) =>
                [
                    { type: 'WorkloadDefinitionById' as const, id: workloadId },
                    { type: 'EdgeDeploymentById' as const, id: edgeDeploymentId }
                ],
        },
        patchEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadIdUpdateToLatest: {
            invalidatesTags: (result, error, { edgeDeploymentId, workloadId }) =>
                [
                    { type: 'EdgeDeploymentById' as const, id: edgeDeploymentId },
                    { type: 'WorkloadDefinitionById' as const, id: workloadId }
                ],
        },
        // deployment secret
        getDeploymentSecretGlobal: {
            providesTags: (result, error) =>
                result ?
                    [{ type: 'DeploymentSecretList' as const, id: 'DeploymentSecretList' }]
                    : ['ERROR'],
        },
        getDeploymentSecretDevice: {
            providesTags: (result, error) =>
                result ?
                    [{ type: 'DeploymentSecretList' as const, id: 'DeploymentSecretList' }]
                    : ['ERROR'],
        },
        getDeploymentSecretSite: {
            providesTags: (result, error) =>
                result ?
                    [{ type: 'DeploymentSecretList' as const, id: 'DeploymentSecretList' }]
                    : ['ERROR'],
        },
        postDeploymentSecret: {
            invalidatesTags: (result, error, deploymentSecret) =>
                [
                    { type: 'DeploymentSecretList' as const, id: 'DeploymentSecretList' }
                ],
        },
        deleteDeploymentSecret: {
            invalidatesTags: (result, error, deploymentSecret) =>
                [
                    { type: 'DeploymentSecretList' as const, id: 'DeploymentSecretList' }
                ],
        },
        // report
        getReportEdgesCountByRegion: {
            providesTags: (result, error) =>
                result ?
                    [{ type: 'EdgesCountPerRegion' as const, id: 'EdgesCountPerRegion' }]
                    : ['ERROR'],
        },
        // user roles
        getRoleAssignmentLoggedInUser: {
            providesTags: (result, error) =>
                result ?
                    [{ type: 'LoggedInUserRoles' as const, id: 'loggedInUserRoles' }]
                    : ['ERROR'],
        },
        getRoleAssignment: {
            // need to be included when using forceRefetch or merge
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName
            },
            // ignore cache in case args are changed
            forceRefetch({ currentArg, previousArg }) {
                return currentArg?.page !== previousArg?.page
            },
            // use this for infinite scroll mechanism
            merge: (currentCache, newItems) => {
                if (newItems.pageNumber === 1)
                    return newItems;
                return {
                    ...newItems,
                    userRoles: [...currentCache.userRoles ?? [], ...newItems.userRoles ?? []]
                }
            },
            providesTags: (result, error) =>
                result ?
                    [{ type: 'UserRoleList' as const, id: 'userRoleList' }]
                    : ['ERROR'],
        },
        getRoleAssignmentByRoleName: {
            providesTags: (result, error, { roleName }) =>
                result ?
                    [{ type: 'UserRoleListByRole' as const, id: roleName }]
                    : ['ERROR'],
        },
        postRoleAssignment: {
            invalidatesTags: (result, error, userRole) =>
                [
                    { type: 'UserRoleList' as const, id: 'userRoleList' },
                    { type: 'UserRoleListByRole' as const },
                    { type: 'LoggedInUserRoles' as const, id: 'loggedInUserRoles' }
                ],
        },
        deleteRoleAssignmentByUserUpnAndRoleName: {
            invalidatesTags: (result, error, { userUpn, roleName }) =>
                [
                    { type: 'UserRoleList' as const, id: 'userRoleList' },
                    { type: 'UserRoleListByRole' as const, id: roleName },
                    { type: 'LoggedInUserRoles' as const, id: 'loggedInUserRoles' }
                ],
        },
        // role definition
        getRoleDefinitionAllRoles: {
            providesTags: (result, error) =>
                result ?
                    [{ type: 'AllRoleDefinition' as const, id: 'AllRoleList' }]
                    : ['ERROR'],
        },
        getRoleDefinition: {
            // need to be included when using forceRefetch or merge
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName
            },
            // ignore cache in case args are changed
            forceRefetch({ currentArg, previousArg }) {
                return currentArg?.page !== previousArg?.page
            },
            // use this for infinite scroll mechanism
            merge: (currentCache, newItems) => {
                if (newItems.pageNumber === 1)
                    return newItems;
                return {
                    ...newItems,
                    roles: [...currentCache.roles ?? [], ...newItems.roles ?? []]
                }
            },
            providesTags: (result, error) =>
                result ?
                    [{ type: 'RoleDefinition' as const, id: 'RoleListByPage' }]
                    : ['ERROR'],
        },
        postRoleDefinition: {
            invalidatesTags: (result, error) =>
                [
                    { type: 'AllRoleDefinition' as const, id: 'AllRoleList' },
                    { type: 'RoleDefinition' as const, id: 'RoleListByPage' }
                ],
        },
        getRoleDefinitionByRoleNameSchema: {
            providesTags: (result, error, { roleName }) =>
                result ?
                    [{ type: 'RoleDefinitionSchema' as const, id: roleName }]
                    : ['ERROR'],
        },
        deleteRoleDefinitionByRoleName: {
            invalidatesTags: (result, error, { roleName }) =>
                [
                    { type: 'AllRoleDefinition' as const, id: 'AllRoleList' },
                    { type: 'RoleDefinition' as const, id: 'RoleListByPage' },
                    { type: 'RoleDefinitionSchema' as const, id: roleName },
                    { type: 'UserRoleList' as const, id: 'userRoleList' },
                ],
        },
        // notification email address
        getNotification: {
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName
            },
            forceRefetch({ currentArg, previousArg }) {
                return currentArg?.page !== previousArg?.page
            },
            merge: (currentCache, newItems) => {
                if (newItems.pageNumber === 1)
                    return newItems;
                return {
                    ...newItems,
                    notificationEmailAddresses: [...currentCache.notificationEmailAddresses ?? [], ...newItems.notificationEmailAddresses ?? []]
                }
            },
            providesTags: (result, error) =>
                result ?
                    [{ type: 'NotificationEmailAddressList' as const, id: 'NotificationEmailAddressList' }]
                    : ['ERROR'],
        },
        getNotificationPurpose: {
            providesTags: (result, error) =>
                result ?
                    [{ type: 'NotificationPurpose' as const, id: 'NotificationPurpose' }]
                    : ['ERROR'],
        },
        postNotification: {
            invalidatesTags: (result, error) =>
                [
                    { type: 'NotificationEmailAddressList' as const, id: 'NotificationEmailAddressList' },
                ],
        },
        deleteNotificationByEmailAddressAndPurpose: {
            invalidatesTags: (result, error, { emailAddress, purpose }) =>
                [
                    { type: 'NotificationEmailAddressList' as const, id: 'NotificationEmailAddressList' },
                ],
        },
        // site
        getHubSiteBySiteIdEdges: {
            providesTags: (result, error, { siteId }) =>
                result ?
                    [{ type: 'SiteEdgeList' as const, id: siteId }]
                    : ['ERROR'],
        },
        getWorkloadDefinitionSearch: {
            providesTags: (result, error, { searchText }) =>
                [{ type: 'WorkloadDefinitionBySearchText' as const, id: searchText }]
        },
        //audit events
        getAuditEvent: {
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName
            },
            forceRefetch({ currentArg, previousArg }) {
                return currentArg?.page !== previousArg?.page
            },
            merge: (currentCache, newItems) => {
                if (newItems.pageNumber === 1)
                    return newItems;
                return {
                    ...newItems,
                    auditEvents: [...currentCache.auditEvents ?? [], ...newItems.auditEvents ?? []]
                }
            },
            providesTags: (result, error) =>
                result ?
                    [{ type: 'AuditEventList' as const, id: 'auditEventList' }]
                    : ['ERROR'],
        }
    }
});

export const {
    useGetHubByIotHubIdEdgesQuery,
    useGetDeviceCertificateInfoByDeviceIdQuery,
    usePostDeviceCertificateRenewMutation,
    useDeleteEdgeByIotHubIdAndDeviceIdMutation,
    useGetEdgeRequestQuery,
    usePostEdgeRequestMutation,
    useGetEdgeRequestByEdgeRequestIdQuery,
    useDeleteEdgeRequestByEdgeRequestIdWithdrawMutation,
    usePostEdgeByIotHubIdAndDeviceIdMoveEdgeRegistrationMutation,
    usePatchEdgeRequestByEdgeRequestIdConfirmMoveRegistrationMutation,
    usePatchEdgeRequestByEdgeRequestIdCompleteMutation,
    useDeleteEdgeRequestByEdgeRequestIdMarkInvalidMutation,
    usePatchEdgeRequestByEdgeRequestIdRedeployMutation,
    usePatchEdgeRequestByEdgeRequestIdMutation,
    usePostEdgeByIotHubIdAndDeviceIdInstallCertificatesMutation,
    useGetConfigurationFeaturesQuery,
    usePostEdgeDeploymentDraftMutation,
    useGetEdgeDeploymentByDeploymentIdQuery,
    useDeleteEdgeDeploymentByDeploymentIdMutation,
    useGetEdgeDeploymentDraftByDeviceIdQuery,
    useGetEdgeDeploymentByEdgeDeploymentIdConfigurationQuery,
    useGetEdgeDeploymentListQuery,
    usePostEdgeDeploymentByEdgeDeploymentIdConfigurationMutation,
    usePatchEdgeDeploymentByDeploymentIdMutation,
    usePostEdgeDeploymentByEdgeDeploymentIdCloneMutation,
    usePostEdgeRequestByEdgeRequestIdRestartWorkflowMutation,
    useGetModuleDefinitionQuery,
    usePostModuleDefinitionMutation,
    usePatchModuleDefinitionByModuleDefinitionIdArchiveMutation,
    useGetModuleDefinitionByModuleDefinitionIdQuery,
    useGetWorkloadDefinitionQuery,
    usePostWorkloadDefinitionMutation,
    usePostEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadIdMutation,
    usePatchEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadIdMutation,
    useDeleteEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadIdMutation,
    useGetWorkloadDefinitionByWorkloadDefinitionIdSchemaQuery,
    usePatchEdgeDeploymentByEdgeDeploymentIdWorkloadAndWorkloadIdUpdateToLatestMutation,
    usePostEdgeDeploymentByEdgeDeploymentIdRefreshStatusMutation,
    useGetDeploymentSecretGlobalQuery,
    useGetDeploymentSecretSiteQuery,
    useGetDeploymentSecretDeviceQuery,
    usePostDeploymentSecretMutation,
    useDeleteDeploymentSecretMutation,
    useGetReportEdgesCountByRegionQuery,
    useGetRoleDefinitionAllRolesQuery,
    useGetRoleAssignmentQuery,
    usePostRoleAssignmentMutation,
    useDeleteRoleAssignmentByUserUpnAndRoleNameMutation,
    useGetRoleAssignmentLoggedInUserQuery,
    useGetRoleDefinitionQuery,
    useDeleteRoleDefinitionByRoleNameMutation,
    useGetRoleDefinitionByRoleNameSchemaQuery,
    usePostRoleDefinitionMutation,
    useGetNotificationQuery,
    useGetNotificationPurposeQuery,
    usePostNotificationMutation,
    useDeleteNotificationByEmailAddressAndPurposeMutation,
    useGetHubSiteBySiteIdEdgesQuery,
    useGetWorkloadDefinitionSearchQuery,
    useGetAuditEventQuery
} = enhancedEdgeToolApi;
