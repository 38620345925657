import { Navigate, Outlet, useParams } from "react-router-dom";
import { showMessageBar } from "../../app/messageBarSlice";
import { useAppDispatch } from "../../app/hooks";
import { useEffect } from "react";

export const SiteIdValidationPage = ({
    redirectPath = '/',
    children
}: {
    redirectPath?: string,
    children?: React.ReactNode,
}) => {

    const dispatch = useAppDispatch();
    const { siteId } = useParams();
    const siteIdPattern = new RegExp("^[0-9]+$");
    const isSiteIdValid = siteIdPattern.test(siteId ?? "");

    useEffect(() => {
        if (!isSiteIdValid) {
            const message = `You are being redirected to dashboard because provided site id was not in correct format. Site id should contain only numbers.`;
            dispatch(showMessageBar({ message, type: "warning" }));
        }
    }, [
        isSiteIdValid, 
        dispatch
    ]);

    if(!isSiteIdValid)
        return <Navigate to={redirectPath} replace />;

    return children ?
        <>{children}</> : <Outlet />;
};
